import { TEXT_ERROR } from '../../Constants'
import SwalCustom from '../alert/swal-custom'

function ErrorCustomFalse({ msg = 'พบข้อผิดพลาด' }) {
  return SwalCustom({
    title: `${typeof msg === 'object' ? msg[0]?.msg : msg}`,
    text: `${TEXT_ERROR}`,
    icon: 'warning',
    showCancelButton: false,
    confirmButtonColor: 'var(--comp-badge-color-background-warning)',
    confirmButtonText: 'Close'
  })
}

export default ErrorCustomFalse
